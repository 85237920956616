.App {
  text-align: center;
}

.App-header {
  margin: 32px 0;
}

.App-headlineLink {
  color: inherit;
  text-decoration: none;
}

.App-headline {
  margin: 0;
  padding: 0;
  font-size: 2em;
}

.App-tagline {
  margin: 0;
  padding: 0;
}

.App-instructions {
  max-width: 530px;
  margin: 3em auto 0;
}

.ShipIconOption {
  padding: 0;
  margin: 0;
}

.ShipIconOption-image {
  width: 1.5em;
  margin-right: 0.25em;
}

.PointBreakdown {
  display: grid;
  grid-auto-columns: 1fr;
  grid-auto-flow: column;
  align-items: start;
}

.PointBreakdown-headline {
  display: block;
  margin: 0;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  font-size: 1.5rem;
  line-height: 1.334;
  letter-spacing: 0em;
  text-align: left;
}

.ItemList .ItemList-item {
  position: relative;
  padding: 1em 0 0;
}

@media screen and (max-width: 620px) {
  .PointBreakdown {
    display: block;
  }
}